import React, { FC } from 'react'
import { graphql, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Card } from 'styles/global'
import InvestorData from 'content/investor.json'
import Layout from 'components/Layout'
import FAQ from 'components/FAQ'
import MainForm from 'components/Form/MainForm'
import Hero from 'components/Hero'
import {
  Container,
  SimpleGrid,
  Box,
  Heading,
  Button,
  Text,
} from '@chakra-ui/react'

const InvestorPage: FC = () => {
  const { t, i18n } = useTranslation()
  const { productsList, developmentList } = InvestorData
  const homePath = i18n.language === 'en' ? '/' : '/fr'

  return (
    <Layout>
      <Hero
        title={t('investor.hero.title')}
        description={t('investor.hero.subtitle')}
        button={
          <Button size="lg" variant="primary">
            {t('investor.hero.button')}
          </Button>
        }
        image={
          <StaticImage
            style={{ height: '100%' }}
            src="../assets/images/investor/hero.png"
            alt="investor hero"
            placeholder="blurred"
          />
        }
      />
      <Container bg="blue.100" textAlign="center">
        <Box py={['4rem', '6rem']}>
          <Heading size="lg" mb="1.5rem" color="black.400">
            {t('investor.content.title')}
          </Heading>
          <Text size="lg" color="gray.400" maxW="768px" mx="auto">
            {t('investor.content.description')}
          </Text>
        </Box>
      </Container>
      <Container>
        <Box py={['4rem', '6rem']} textAlign="center">
          <Heading size="md" mb={['2rem', '6rem']}>
            {t('investor.products.title')}
          </Heading>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
            spacing={['2rem', '6rem 2rem']}
          >
            {productsList.map((product, idx) => (
              <Card key={idx} borderColor="gray.200">
                <Text
                  size="xxl"
                  fontWeight="700"
                  color="gray.400"
                  textTransform="uppercase"
                >
                  {product.text}
                </Text>
                <Button
                  w="full"
                  mt="8"
                  variant="oxford"
                  onClick={() =>
                    navigate(homePath, {
                      state: {
                        scrollTo: 'home-products',
                        action: {
                          type: 'updateProductTab',
                          index: product.sectionIdx,
                        },
                      },
                    })
                  }
                >
                  {t('investor.products.button')}
                </Button>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
      <Container bg="blue.100" textAlign="center">
        <Box py={['4rem', '6rem']}>
          <Heading size="md" mb={['2rem', '6rem']} color="black.400">
            {t('investor.inDevelopment.title')}
          </Heading>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
            spacing={['2rem', '6rem 2rem']}
          >
            {developmentList.map((product, idx) => (
              <Card key={idx} borderColor="gray.200" bg="white">
                <Text
                  size="xxl"
                  fontWeight="700"
                  color="gray.400"
                  textTransform="uppercase"
                >
                  {product}
                </Text>
                <Button
                  w="full"
                  mt="8"
                  variant="oxford"
                  onClick={() =>
                    navigate(homePath, {
                      // state: {
                      //   scrollTo: 'home-benefits',
                      //   action: {
                      //     type: 'updateBenefitsTab',
                      //     index: idx,
                      //   },
                      // },
                    })
                  }
                >
                  {t('investor.inDevelopment.button')}
                </Button>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
      <FAQ
        title={t('earlyAdopters.faq.title')}
        subtitle={t('earlyAdopters.faq.subtitle')}
        data={t('earlyAdopters.faq.list', { returnObjects: true }) || []}
      />
      <MainForm
        title={t('earlyAdopters.form.title')}
        subtitle={t('earlyAdopters.form.subtitle')}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default InvestorPage
